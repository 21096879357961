#mainContainer {
    min-height: calc(98vh - 2.5rem - 75px);
}
#mainContainer > main {
    padding: 1rem 0;
}
.container {
    flex-flow: wrap;
}
#toastContainer {
    z-index: 10;
}
#mainContainer > main > form > div.frc-captcha {
    position: relative;
}
#mainContainer > main > form > div.frc-captcha > div > div {
    overflow-x: inherit;
}
body {
    overscroll-behavior-y: contain;
}

/* https://stackoverflow.com/a/20495972/ 🤦‍♀️ */
*:not(input.field) {
    user-select: none;
}
